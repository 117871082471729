import React from "react";
import {
  Alert,
  Box,
  Button, ColumnLayout,
  Flashbar,
  FlashbarProps, Form, FormField, Grid,
  Header,
  Input,
  InputProps,
  Modal, NonCancelableCustomEvent, RadioGroup, RadioGroupProps, Select, SelectProps,
  SpaceBetween,
  Tabs,
  Container,
} from "@amzn/awsui-components-react";
import { BLANK_HARDWARE_ID, BLANK_SESSION_ID, Xterm } from "./Xterm";
import { hideLoading, showLoading } from "./Loading";
import { TabsProps } from "@amzn/awsui-components-react/polaris/tabs/interfaces";
import {
  GetAvailabilityZonesFromLocalStorage,
  GetUserFromLocalStorage,
  GetFabricsFromLocalStorage
} from "../utils/dreamscape";
import {
    CreateSessionRequest,
    DefaultApi,
    Fabric,
    GetBMCInfoRequest,
    SessionType
} from "../dreamscape-api/generated-src";
import DreamscapeApiFactory from "../dreamscape-api/DreamscapeApiFactory";

const BMC_CONNECT = "bmc";
const AZ_CONNECT = "az-fabric";
const AZ_FABRIC_SESSION_TYPES: SelectProps.Option[] = [
  { label: "Shell", value: "shell", description: "Launch shell to execute arbitrary commands" },
  { label: "Tmux", value: "tmux", description: "Launch Tmux to have several shells with same access" },
];
const BMC_SPECIFIC_SESSION_TYPES: SelectProps.Option[] = [
  { label: "Inception", value: "inception", description: "Launch Inception to selected BMC" },
  { label: "SOL", value: "sol", description: "Launch SOL sessiosn to selected BMC" }
]
const BMC_SESSION_TYPES: SelectProps.Option[] = AZ_FABRIC_SESSION_TYPES.concat(BMC_SPECIFIC_SESSION_TYPES);
const ROLE_NAME_PREFIXES: SelectProps.Option[] = [
  { label: "gts-operator-", value: "gts-operator-" }
];

interface XtermSessionProps {

}

interface XtermSessionState {
  user: any
  availabilityZones: SelectProps.Option[]
  fabrics: SelectProps.Option[]
  flash: FlashbarProps.MessageDefinition[]
  showErrorModal: boolean
  showRenameModal: boolean
  errorMessage: string
  errorMessages: string[] | undefined
  sessions: TabsProps.Tab[]
  terminals: JSX.Element[]
  activeSessionId: string
  showConnectModal: boolean
  connectDisabled: boolean
  searchDisabled: boolean
  federationDisabled: boolean
  sessionTypes: SelectProps.Option[]
  bmcAttrs: BMCAttrs
  newTabName: string
  connectFormType: string
}

interface BMCAttr {
  label: string
  value: string
  readOnly: boolean
}

interface BMCAttrs {
  sessionType: BMCAttr
  macAddress: BMCAttr
  hardwareId: BMCAttr
  assetId: BMCAttr
  ipAddress: BMCAttr
  availabilityZone: BMCAttr
  fabric: BMCAttr
  roleNamePrefix: BMCAttr
  roleNameSuffix: BMCAttr
}


export const XtermSession = () => {
  return (
    <XtermSessionComponent/>
  );
}

export class XtermSessionComponent extends React.Component<XtermSessionProps, XtermSessionState> {
  dreamscapeApi: DefaultApi

  constructor(props: XtermSessionProps) {
    super(props);
    this.state = {
      user: GetUserFromLocalStorage(),
      availabilityZones: GetAvailabilityZonesFromLocalStorage()
      .filter((az) => az.name !== undefined && az.name!.length > 3)
      .sort((az1, az2) => parseInt(az1.name!.substring(3)) - parseInt(az2.name!.substring(3)))
      .map((it) => {
        return { value: it.name, label: it.name }
      }),
      fabrics: GetFabricsFromLocalStorage().sort().map((it) => {
        return { value: it, label: it };
      }),
      flash: [],
      showRenameModal: false,
      showErrorModal: false,
      errorMessage: "",
      errorMessages: undefined,
      sessions: [],
      terminals: [],
      activeSessionId: "",
      showConnectModal: false,
      connectDisabled: true,
      searchDisabled: false,
      federationDisabled: true,
      bmcAttrs: this.getBMCAttrs(BMC_CONNECT),
      sessionTypes: BMC_SESSION_TYPES,
      newTabName: "",
      connectFormType: BMC_CONNECT
    };
    this.closeXterm = this.closeXterm.bind(this);
    this.dreamscapeApi = DreamscapeApiFactory();
  }

  getBMCAttrs = (connectType: string): BMCAttrs => {
    let sessionTypes = BMC_SESSION_TYPES;
    if (connectType === BMC_CONNECT) {
      sessionTypes = BMC_SESSION_TYPES;
    } else if (connectType === AZ_CONNECT) {
      sessionTypes = AZ_FABRIC_SESSION_TYPES;
    }
    return {
      "sessionType": {
        label: "Session Type",
        value: sessionTypes[0].value || "shell",
        readOnly: false
      },
      "macAddress": {
        label: "MAC Address",
        value: "",
        readOnly: connectType !== BMC_CONNECT
      },
      "hardwareId": {
        label: "Hardware ID",
        value: "",
        readOnly: connectType !== BMC_CONNECT
      },
      "assetId": {
        label: "Asset ID",
        value: "",
        readOnly: connectType !== BMC_CONNECT
      },
      "ipAddress": {
        label: "IP Address",
        value: "",
        readOnly: true
      },
      "availabilityZone": {
        label: "Availability Zone",
        value: "",
        readOnly: connectType === BMC_CONNECT
      },
      "fabric": {
        label: "Fabric",
        value: "",
        readOnly: connectType === BMC_CONNECT
      },
      "roleNamePrefix": {
        label: "",
        value: "gts-operator-",
        readOnly: false
      },
      "roleNameSuffix": {
        label: "",
        value: "",
        readOnly: false
      }
    }
  }

  componentDidMount() {
    this.createXterm(BLANK_SESSION_ID, BLANK_HARDWARE_ID, false);

    showLoading();
    this.dreamscapeApi.listActiveSessions()
    .then(resp => {
      hideLoading();
      if (resp.data.error) {
        this.showError(resp.data.error, resp.data.errors);
        return;
      }
      let showConnect = true;
      const sessions = resp.data.sessions || []
      for (let session of sessions) {
        if (session.sessionId && session.sessionName) {
          this.createXterm(session.sessionId, session.sessionName);
          showConnect = false;
        }
      }
      if (showConnect) {
        this.selectSession(this.state.sessions[0].id);
      }
    }).catch(reason => {
      hideLoading();
      this.showError(`Error occurred getting list of open sessions...`);
      console.error(reason);
      this.selectSession(BLANK_SESSION_ID);
    });
  }

  dismissFlash = (flashId: string | undefined) => {
    if (flashId === undefined) {
      return;
    }
    let flash = this.state.flash;
    flash = flash.filter((item) => item.dismissLabel !== flashId);
    this.setState({
      flash: flash
    });
  }

  getBMCInfo = () => {
    const macAddress = this.state.bmcAttrs["macAddress"]?.value;
    const hardwareId = this.state.bmcAttrs["hardwareId"]?.value;
    const assetId = this.state.bmcAttrs["assetId"]?.value;
    if ([macAddress, hardwareId, assetId].every(v => v === undefined || v === "")) {
      return
    }
    const getBMCInfoRequest: GetBMCInfoRequest = {};
    if (macAddress !== undefined && macAddress !== "") {
      getBMCInfoRequest.macAddress = macAddress;
    }
    if (hardwareId !== undefined && hardwareId !== "") {
      getBMCInfoRequest.hardwareId = hardwareId;
    }
    if (assetId !== undefined && assetId !== "") {
      getBMCInfoRequest.assetId = assetId;
    }
    showLoading();
    this.dreamscapeApi.getBMCInfo(getBMCInfoRequest)
    .then(resp => {
      hideLoading();
      if (resp.data.error) {
        this.showError(resp.data.error, resp.data.errors);
        return;
      }
      const bmc = resp.data.bmc!;
      if (bmc.hardwareId) {
        let bmcAttrs = this.state.bmcAttrs;
        type bmcAttrKey = keyof typeof bmcAttrs;
        for (let k in this.state.bmcAttrs) {
          if (bmc.hasOwnProperty(k)) {
            bmcAttrs[k as bmcAttrKey].value = bmc[k];
          }
        }
        this.setState({
          connectDisabled: false,
          federationDisabled: bmcAttrs.fabric.value !== "ec2",
          bmcAttrs: bmcAttrs
        });
      }
    })
    .catch(reason => {
      hideLoading();
      this.showError("Error occurred getting BMC information...");
      console.error(reason);
    });
  }

  federationAccountEmail = () => {
    return `ec2-gts+operator+${this.state.bmcAttrs.availabilityZone.value.toUpperCase()}@amazon.com`;
  }

  createTabLabel = (sessionId: string, sessionName: string) => {
    return <SpaceBetween
      size={"xs"}
      direction={"horizontal"}>
      {sessionName}
      <Button
        iconName={'edit'}
        variant={'icon'}
        onClick={() => {
          this.setState({
            showRenameModal: true,
            newTabName: ""
          });
        }}
      >
      </Button>
      <Button
        iconName={"close"}
        variant={"icon"}
        onClick={() => {
          this.closeXterm(sessionId);
        }}
      />
    </SpaceBetween>
  }


  createXterm = (sessionId: string, sessionName: string, selectSession: boolean = true) => {
    if (!sessionId || !sessionName) {
      return;
    }
    this.setState((prevState) => ({
      ...prevState,
      sessions: [...(prevState.sessions ?? []), {
        id: sessionId,
        label: (sessionId === BLANK_SESSION_ID) ?
          <Button
            iconName={"add-plus"}
            variant={"icon"}
            onClick={() => {
              this.newSession();
            }}
          />
          :
          this.createTabLabel(sessionId, sessionName)
      }],
      terminals: [...(prevState.terminals ?? []),
        <Xterm
          sessionId={sessionId}
          sessionName={sessionName}
          xtermCols={132}
          xtermRows={43}
          closeXterm={this.closeXterm}/>]
    }));

    if (selectSession) {
      this.selectSession(sessionId);
    }
  }

  closeXterm = (sessionId: string) => {
    // removing div holding Xterm emulator
    const el = document.getElementById(sessionId);
    if (el !== null) {
      const parentEl = el.parentElement;
      if (parentEl !== null) {
        parentEl.removeChild(el);
      }
    }
    showLoading();
    this.dreamscapeApi.closeSession({ sessionId: sessionId })
    .then(resp => {
      hideLoading();
      if (resp.data.error) {
        this.showError(resp.data.error, resp.data.errors);
        return;
      }
      console.info(`Session ${sessionId} has been closed successfully...`);
    })
    .catch(reason => {
      hideLoading();
      this.showError(`Error occurred while closing the session ${sessionId}...`);
      console.error(reason);
    });
    if (this.state.activeSessionId === sessionId) {
      // activating tab right before the one we are about to close unless it's a first one then activating next one
      const sessionIndex = this.state.sessions.findIndex((session) => session.id === sessionId);
      if (this.state.sessions.length > 1) {
        if (sessionIndex === this.state.sessions.length - 1) {
          this.selectSession(this.state.sessions[sessionIndex - 1].id);
        } else {
          this.selectSession(this.state.sessions[sessionIndex + 1].id);
        }
      }
    }
    const sessions = this.state.sessions.filter((session) => session.id !== sessionId);
    const terminals: JSX.Element[] = [];
    this.state.terminals.forEach((terminal) => {
      if (terminal.props.id !== sessionId) {
        terminals.push(terminal);
      }
    });
    this.setState({
      sessions: sessions,
      terminals: terminals
    });
  }

  selectSession = (sessionId: string) => {
    this.state.sessions.forEach((session) => {
      const el = document.getElementById(session.id);
      if (el !== null) {
        if (session.id === sessionId) {
          el.className = "";
        } else {
          el.className = "hidden";
        }
      }
    });
    this.setState({ activeSessionId: sessionId });
    if (sessionId === BLANK_SESSION_ID) {
      this.newSession();
      return;
    }
  }

  renameSession = (sessionId: string, newTabName: string) => {
    if (newTabName === "") {
      this.showError(`New name can not be empty`);
      return;
    }
    this.setState({
      showRenameModal: false,
      newTabName: ""
    });

    //find the new session in the list of terminals using session id
    const index = this.state.sessions.findIndex(i => i.id === sessionId);
    if (index != -1) {
      //call the api to change it to back end
      showLoading();
      this.dreamscapeApi.updateSession({
        sessionId: sessionId,
        sessionName: newTabName
      })
      .then(resp => {
        hideLoading();
        if (resp.data.error) {
          this.showError(resp.data.error, resp.data.errors);
          return;
        }
        console.info(`Session ${sessionId} has been renamed successfully...`);
        const sessions = this.state.sessions;
        this.setState({
          sessions: [
            ...sessions.slice(0, index),
            {
              ...sessions[index],
              label: this.createTabLabel(sessionId, newTabName),
            },
            ...sessions.slice(index + 1)
          ]
        });
      })
      .catch(reason => {
        hideLoading();
        this.showError(`Error occurred while renaming the session ${sessionId}...`);
        console.error(reason);
      });
    }
  }

  createSession = () => {
    const sessionType = this.state.bmcAttrs.sessionType?.value;
    const macAddress = this.state.bmcAttrs.macAddress?.value;
    const hardwareId = this.state.bmcAttrs.hardwareId?.value;
    const assetId = this.state.bmcAttrs.assetId?.value;
    const roleNamePrefix = this.state.bmcAttrs.roleNamePrefix?.value;
    const roleNameSuffix = this.state.bmcAttrs.roleNameSuffix?.value;
    const availabilityZone = this.state.bmcAttrs.availabilityZone?.value;
    const fabric = this.state.bmcAttrs.fabric?.value;

    if (sessionType === undefined || sessionType === "") {
      this.showError("You must select session type");
      return;
    }
    // let url = "";
    const createSessionRequest: CreateSessionRequest = {
      sessionType: sessionType as SessionType
    }
    if (this.state.connectFormType === BMC_CONNECT) {
      if ([macAddress, hardwareId, assetId].every(v => v === undefined || v === "")) {
        this.showError("At least one of MAC Address, Hardware ID or Asset ID is needed to create new session");
        return
      }
      if (macAddress !== undefined && macAddress !== "") {
        createSessionRequest.macAddress = macAddress;
      }
      if (hardwareId !== undefined && hardwareId !== "") {
        createSessionRequest.hardwareId = hardwareId;
      }
      if (assetId !== undefined && assetId !== "") {
        createSessionRequest.assetId = assetId;
      }
    } else if (this.state.connectFormType === AZ_CONNECT) {
      if (availabilityZone === undefined || availabilityZone === "") {
        this.showError("You need to select Availability Zone from the list");
        return;
      }
      if (fabric === undefined || fabric === "") {
        this.showError("You need to select Fabric from the list");
        return;
      }
      createSessionRequest.availabilityZone = availabilityZone;
      createSessionRequest.fabric = fabric as Fabric;
    } else {
      this.showError("Unable to determine connection parameters");
      return;
    }
    const federationRoleName = (roleNamePrefix && roleNameSuffix) ? `${roleNamePrefix}${roleNameSuffix}` : undefined;
    const federationAccountEmail = this.federationAccountEmail();
    if (federationRoleName && federationAccountEmail) {
      createSessionRequest.federationRoleName = federationRoleName;
      createSessionRequest.federationAccountEmail = federationAccountEmail;
    }
    showLoading();
    this.dreamscapeApi.createSession(createSessionRequest)
    .then(resp => {
      hideLoading();
      if (resp.data.error) {
        this.showError(resp.data.error, resp.data.errors);
        return;
      }
      this.setState({ showConnectModal: false });
      const session = resp.data.session!;
      this.createXterm(session.sessionId!, session.sessionName!);
      hideLoading();
    })
    .catch(reason => {
      hideLoading();
      this.showError("Error occurred when creating session...");
      console.log(reason);
    });
  }

  newSession = () => {
    this.setState({
      showConnectModal: true,
      connectDisabled: true,
      searchDisabled: false,
      connectFormType: BMC_CONNECT,
      sessionTypes: BMC_SESSION_TYPES,
      bmcAttrs: this.getBMCAttrs(BMC_CONNECT)
    });
  }

  showError = (message: string, messages: string[] | undefined = undefined) => {
    this.setState({
      showErrorModal: true,
      errorMessage: message,
      errorMessages: messages
    });
  }

  canConnectToAzFabric = (): boolean => {
    return true;
    // return false;
  }

  connectFormType = (value: string) => {
    if (value === BMC_CONNECT) {
      this.setState({
        connectFormType: value,
        searchDisabled: false,
        connectDisabled: true,
        sessionTypes: BMC_SESSION_TYPES,
        bmcAttrs: this.getBMCAttrs(BMC_CONNECT)
      });
    } else if (value === AZ_CONNECT) {
      if (!this.canConnectToAzFabric()) {
        this.showError("You are only allowed to connect to single BMC");
        return;
      }
      this.setState({
        connectFormType: value,
        searchDisabled: true,
        connectDisabled: false,
        sessionTypes: AZ_FABRIC_SESSION_TYPES,
        bmcAttrs: this.getBMCAttrs(AZ_CONNECT)
      });
    }
  }

  errorMessageList = (errorMessages: string[] | undefined) => {
    if (errorMessages === undefined) {
      return <></>;
    }
    const errorMessageListItems = errorMessages.map((errorMessage) => {
      return <li>{errorMessage}</li>;
    });
    return <ul>{errorMessageListItems}</ul>
  }

  render = () => {
    return <>
      <Flashbar items={this.state.flash}/>
      <Modal
        visible={this.state.showConnectModal}
        header={"Connect"}
        size={"large"}
        onDismiss={() => {
          this.setState({ showConnectModal: false })
        }}
        footer={
          <Box float="right">
            <SpaceBetween direction="horizontal" size="xs">
              <Button
                variant={"primary"}
                disabled={this.state.connectDisabled}
                onClick={() => {
                  this.createSession()
                }}>
                Connect
              </Button>
            </SpaceBetween>
          </Box>
        }
      >
        <Modal
          visible={this.state.showErrorModal}
          header={"Error"}
          size={"medium"}
          onDismiss={() => {
            this.setState({ showErrorModal: false, errorMessage: "" })
          }}
          footer={""}
        >
          <Alert
            visible={true}
            header={"Error"}
            type={"error"}
          >
            {this.state.errorMessage}
            {this.errorMessageList(this.state.errorMessages)}
          </Alert>
        </Modal>

        <Modal
          onDismiss={() => {
            this.setState({ showRenameModal: false, newTabName: "" });
          }}
          visible={this.state.showRenameModal}
          closeAriaLabel="Close modal"
          footer={
            <Box float="right">
              <SpaceBetween direction="horizontal" size="xs">
                <Button variant="primary" onClick={() => {
                  this.renameSession(this.state.activeSessionId, this.state.newTabName);
                }}>Change Name</Button>
              </SpaceBetween>
            </Box>
          }
          header="Rename Tab"
        >
          <FormField
            label="Input new tab name"
          >
            <Input value={this.state.newTabName} placeholder={"New name"} onChange={
              (event: NonCancelableCustomEvent<InputProps.ChangeDetail>) => {
                this.setState({ newTabName: event.detail.value })
              }}/>
          </FormField>
        </Modal>

        <Form>
          <ColumnLayout columns={2}>
            <div>
              <RadioGroup
                value={this.state.connectFormType}
                onChange={(event: NonCancelableCustomEvent<RadioGroupProps.ChangeDetail>) => this.connectFormType(event.detail.value)}
                items={[
                  { value: BMC_CONNECT, label: "BMC", description: "Limit access to single BMC" },
                ]}
              />
              <Container>
                <SpaceBetween size={"s"}>
                  <FormField
                    label={this.state.bmcAttrs.macAddress.label}>
                    <Input
                      onChange={
                        (event: NonCancelableCustomEvent<InputProps.ChangeDetail>) => {
                          this.state.bmcAttrs.macAddress.value = event.detail.value;
                          this.state.bmcAttrs.hardwareId.value = "";
                          this.state.bmcAttrs.assetId.value = "";
                          this.state.bmcAttrs.ipAddress.value = "";
                          this.setState({
                            searchDisabled: false,
                            connectDisabled: true,
                            bmcAttrs: this.state.bmcAttrs,
                          });
                        }
                      }
                      value={this.state.bmcAttrs.macAddress.value}
                      type={"search"}
                      readOnly={this.state.bmcAttrs.macAddress.readOnly}
                    />
                  </FormField>
                  <FormField
                    label={this.state.bmcAttrs.hardwareId.label}>
                    <Input
                      onChange={
                        (event: NonCancelableCustomEvent<InputProps.ChangeDetail>) => {
                          this.state.bmcAttrs.macAddress.value = "";
                          this.state.bmcAttrs.hardwareId.value = event.detail.value;
                          this.state.bmcAttrs.assetId.value = "";
                          this.state.bmcAttrs.ipAddress.value = "";
                          this.setState({
                            searchDisabled: false,
                            connectDisabled: true,
                            bmcAttrs: this.state.bmcAttrs,
                          });
                        }
                      }
                      value={this.state.bmcAttrs.hardwareId.value}
                      type={"search"}
                      readOnly={this.state.bmcAttrs.hardwareId.readOnly}
                    />
                  </FormField>
                  <FormField
                    label={this.state.bmcAttrs.assetId.label}>
                    <Input
                      onChange={
                        (event: NonCancelableCustomEvent<InputProps.ChangeDetail>) => {
                          this.state.bmcAttrs.macAddress.value = "";
                          this.state.bmcAttrs.hardwareId.value = "";
                          this.state.bmcAttrs.assetId.value = event.detail.value;
                          this.state.bmcAttrs.ipAddress.value = "";
                          this.setState({
                            searchDisabled: false,
                            connectDisabled: true,
                            bmcAttrs: this.state.bmcAttrs,
                          });
                        }
                      }
                      value={this.state.bmcAttrs.assetId.value}
                      type={"search"}
                      readOnly={this.state.bmcAttrs.assetId.readOnly}
                    />
                  </FormField>
                  <FormField
                    label={this.state.bmcAttrs.ipAddress.label}>
                    <Grid gridDefinition={[{ colspan: 100 }, { colspan: 1 }]}>
                      <Input
                        onChange={
                          (event: NonCancelableCustomEvent<InputProps.ChangeDetail>) => {
                            this.state.bmcAttrs.ipAddress.value = event.detail.value;
                            this.setState({ bmcAttrs: this.state.bmcAttrs });
                          }
                        }
                        value={this.state.bmcAttrs.ipAddress.value}
                        type={"text"}
                        readOnly={true}
                      />
                      <Button variant={"icon"} iconName={"edit"}
                              disabled={this.state.bmcAttrs.ipAddress.readOnly && this.state.connectDisabled}/>
                    </Grid>
                  </FormField>
                  <Box float="right">
                    <SpaceBetween direction="horizontal" size="xs">
                      <Button
                        variant={"primary"}
                        iconName={"search"}
                        disabled={this.state.searchDisabled}
                        onClick={() => {
                          this.getBMCInfo()
                        }}>
                        Search
                      </Button>
                    </SpaceBetween>
                  </Box>
                </SpaceBetween>
              </Container>
            </div>
            <div>
              <RadioGroup
                value={this.state.connectFormType}
                onChange={(event: NonCancelableCustomEvent<RadioGroupProps.ChangeDetail>) => this.connectFormType(event.detail.value)}
                items={[
                  {
                    value: AZ_CONNECT,
                    label: "Availability Zone/Fabric",
                    description: "Access to every BMC in selected zone/fabric"
                  }
                ]}
              />
              <Container>
                <SpaceBetween size={"s"}>
                  <FormField
                    label={this.state.bmcAttrs.availabilityZone.label}>
                    <Select
                      selectedOption={this.state.availabilityZones.find((o) => o.value === this.state.bmcAttrs.availabilityZone.value) || null}
                      onChange={
                        (event: NonCancelableCustomEvent<SelectProps.ChangeDetail>) => {
                          this.state.bmcAttrs.availabilityZone.value = event.detail.selectedOption.value || this.state.availabilityZones[0].value || "";
                          this.setState({ bmcAttrs: this.state.bmcAttrs });
                        }
                      }
                      options={this.state.availabilityZones}
                      disabled={this.state.bmcAttrs.availabilityZone.readOnly}
                    />
                  </FormField>
                  <FormField
                    label={this.state.bmcAttrs.fabric.label}>
                    <Select
                      selectedOption={this.state.fabrics.find((o) => o.value === this.state.bmcAttrs.fabric.value) || null}
                      onChange={
                        (event: NonCancelableCustomEvent<SelectProps.ChangeDetail>) => {
                          this.state.bmcAttrs.fabric.value = event.detail.selectedOption.value || this.state.fabrics[0].value || "";
                          this.setState({
                            bmcAttrs: this.state.bmcAttrs,
                            federationDisabled: this.state.bmcAttrs.fabric.value !== "ec2"
                          });
                        }
                      }
                      options={this.state.fabrics}
                      disabled={this.state.bmcAttrs.fabric.readOnly}
                    />
                  </FormField>
                </SpaceBetween>
              </Container>
            </div>
            <FormField
              label={this.state.bmcAttrs.sessionType.label}>
              <Select
                selectedOption={this.state.sessionTypes.find((o) => o.value === this.state.bmcAttrs.sessionType.value) || null}
                onChange={
                  (event: NonCancelableCustomEvent<SelectProps.ChangeDetail>) => {
                    this.state.bmcAttrs.sessionType.value = event.detail.selectedOption.value || this.state.sessionTypes[0].value || "shell";
                    this.setState({ bmcAttrs: this.state.bmcAttrs });
                  }
                }
                options={this.state.sessionTypes}
              />
            </FormField>
            <SpaceBetween size={"s"}>
              <FormField
                label={"Federation Account"}>
                <Input disabled={this.state.federationDisabled}
                       value={this.state.bmcAttrs.availabilityZone.value ? this.federationAccountEmail() : ""}/>
              </FormField>
              <FormField
                label={"Federation Role"}>
                <Grid>
                  <Select
                    disabled={this.state.federationDisabled}
                    selectedOption={ROLE_NAME_PREFIXES.find((o) => o.value === this.state.bmcAttrs.roleNamePrefix.value) || null}
                    onChange={
                      (event: NonCancelableCustomEvent<SelectProps.ChangeDetail>) => {
                        this.state.bmcAttrs.roleNamePrefix.value = event.detail.selectedOption.value || ROLE_NAME_PREFIXES[0].value || "gts-operator-";
                        this.setState({ bmcAttrs: this.state.bmcAttrs });
                      }
                    }
                    options={ROLE_NAME_PREFIXES}/>
                  <Input
                    disabled={this.state.federationDisabled}
                    value={this.state.bmcAttrs.roleNameSuffix.value}
                    onChange={
                      (event: NonCancelableCustomEvent<InputProps.ChangeDetail>) => {
                        this.state.bmcAttrs.roleNameSuffix.value = event.detail.value;
                        this.setState({ bmcAttrs: this.state.bmcAttrs });
                      }
                    }
                  />
                </Grid>
              </FormField>
            </SpaceBetween>
          </ColumnLayout>
        </Form>
      </Modal>
      <Header variant="h1">
        Bastion Sessions
      </Header>
      <SpaceBetween direction={"vertical"} size={"xs"}>
        <Tabs
          activeTabId={this.state.activeSessionId}
          onChange={(event: NonCancelableCustomEvent<TabsProps.ChangeDetail>) => {
            this.selectSession(event.detail.activeTabId)
          }
          }

          tabs={this.state.sessions}
        />
        <Box children={this.state.terminals}/>
      </SpaceBetween>
    </>;
  }
}
